import Asparagus from "./recipes/Asparagus";
import Baked_salmon_filets from "./recipes/Baked_salmon_filets";
import Baked_zucchini from "./recipes/Baked_zucchini";
import Banana_bread from "./recipes/Banana_bread";
import Boiled_eggs from "./recipes/Boiled_eggs";
import Brussel_sprouts from "./recipes/Brussel_sprouts";
import Carrot_cake from "./recipes/Carrot_cake";
import Corn_on_the_cob from "./recipes/Corn_on_the_cob";
import Eggs_sunny_side_up from "./recipes/Eggs_sunny_side_up";
import Fajitas from "./recipes/Fajitas";
import Garlic_sauce from "./recipes/Garlic_sauce";
import Greek_lamb_loaves from "./recipes/Greek_lamb_loaves";
import Greek_lemon_chicken from "./recipes/Greek_lemon_chicken";
import Greek_salad from "./recipes/Greek_salad";
import Greek_pita from "./recipes/Greek_pita";
import Guacamole from "./recipes/Guacamole";
import Horseradish_sauce from "./recipes/Horseradish_sauce";
import Leg_of_lamb from "./recipes/Leg_of_lamb";
import Pancetta_green_beans from "./recipes/Pancetta_green_beans";
import Parsnip_chips from "./recipes/Parsnip_chips";
import Parsnip_cream from "./recipes/Parsnip_cream";
import Pavlova from "./recipes/Pavlova";
import Pico_de_gallo from "./recipes/Pico_de_gallo";
import Pizza from "./recipes/Pizza";
import Pizza_sauce from "./recipes/Pizza_sauce";
import Potatoes_with_onions from "./recipes/Potatoes_with_onions";
import Red_lentil_soup from "./recipes/Red_lentil_soup";
import Roasted_cauliflower_soup from "./recipes/Roasted_cauliflower_soup";
import Shawarma from "./recipes/Shawarma";
import Soda_bread from "./recipes/Soda_bread";
import Tartare_sauce from "./recipes/Tartare_sauce";
import Tomato_chicken from "./recipes/Tomato_chicken";
import Turkey_escalopes_butter_lemon from "./recipes/Turkey_escalopes_butter_lemon";
import Varza_calita from "./recipes/Varza_calita";
import Yoghurt_sauce from "./recipes/Yoghurt_sauce";

export const recipes = {
  Asparagus: Asparagus,
  Baked_salmon_filets: Baked_salmon_filets,
  Baked_zucchini: Baked_zucchini,
  Banana_bread: Banana_bread,
  Boiled_eggs: Boiled_eggs,
  Brussel_sprouts: Brussel_sprouts,
  Carrot_cake: Carrot_cake,
  Corn_on_the_cob: Corn_on_the_cob,
  Eggs_sunny_side_up: Eggs_sunny_side_up,
  Fajitas: Fajitas,
  Garlic_sauce: Garlic_sauce,
  Greek_lamb_loaves: Greek_lamb_loaves,
  Greek_lemon_chicken: Greek_lemon_chicken,
  Greek_salad: Greek_salad,
  Greek_pita: Greek_pita,
  Guacamole: Guacamole,
  Horseradish_sauce: Horseradish_sauce,
  Leg_of_lamb: Leg_of_lamb,
  Pancetta_green_beans: Pancetta_green_beans,
  Parsnip_chips: Parsnip_chips,
  Parsnip_cream: Parsnip_cream,
  Pavlova: Pavlova,
  Pico_de_gallo: Pico_de_gallo,
  Pizza: Pizza,
  Pizza_sauce: Pizza_sauce,
  Potatoes_with_onions: Potatoes_with_onions,
  Red_lentil_soup: Red_lentil_soup,
  Roasted_cauliflower_soup: Roasted_cauliflower_soup,
  Shawarma: Shawarma,
  Soda_bread: Soda_bread,
  Tartare_sauce: Tartare_sauce,
  Tomato_chicken: Tomato_chicken,
  Turkey_escalopes_butter_lemon: Turkey_escalopes_butter_lemon,
  Varza_calita: Varza_calita,
  Yoghurt_sauce: Yoghurt_sauce,
};

export const recipeList = Object.keys(recipes);
