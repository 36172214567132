import Equipment from "./../equipment";
import Ingredients from "./../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.bread,

  title: "Pavlova",

  subtitle: "With strawberries",

  background: require("../../media/pavlova.jpg"),

  details: ["This recipe makes one large pavlova (1-2 portions)", "Make it fancy by decorating with edible flowers"],

  ingredients: [
    Ingredients.Egg(1, "just egg white"),
    Ingredients.Sugar("60g CASTER"),
    Ingredients.Vinegar(2, "1/4 teaspoons white wine vinegar"),
    Ingredients.Cornflour("1/4 teaspoons"),
    Ingredients.Vanila("1/4 teaspoons"),

    Ingredients.Strawberries("150g", "hulled and cut"),
    Ingredients.Sugar("2 teaspoons ICING", "for the sauce"),
    Ingredients.Sugar("1 teaspoon ICING", "for the cream"),
    Ingredients.Double_cream("100ml"),
  ],

  optional_ingredients: [
    Ingredients.Blackberries("For decoration"),
    Ingredients.Blueberries("For decoration"),
    Ingredients.Raspberries("For decoration"),
    Ingredients.Kiwi("For decoration"),
    Ingredients.Mint("For decoration"),
    Ingredients.Banana("For decoration"),
  ],

  equipment: [
    Equipment.Mixer("handheld", "with wisk attachments"),
    Equipment.Vertical_blender("IMPORTANT"),
    Equipment.Mixing_bowl("large"),
    Equipment.Spatula("soft"),
    Equipment.Oven_gloves(),
    Equipment.Baking_tray("large"),
    Equipment.Baking_paper("to line the tray"),
  ],

  optional_equipment: [],

  prep: ["Hull and cut the strawberries", "Separate the egg white", "Heat oven to 150C/130C fan"],

  prep_time: 20,

  method: [
    "Whisk the egg white whites with a hand mixer until it forms stiff peaks",
    "Whisk in the caster sugar, 1 tbsp at a time, until the meringue looks glossy",
    "Whisk in the white wine vinegar",
    "Whisk in the cornflour",
    "Whisk in the vanilla extract",
    "Spread the meringue on the baking paper in the tray in a round shape (approx. 15cm diameter)",
    "Create a crater by making the sides a little higher than the middle",
    "Bake for 40 minutes",
    "Let the meringue cool on the tray after baking, at least 1h",
    "SAUCE: blend half of the strawberries with 2 teaspoons of icing sugar",
    "CREAM: whip the double cream with 1 teaspoon of icing sugar",
    "Spread the cream over the meringue",
    "Pour the souce on top",
    "Cover with the rest of the strawberries",
    "(optional) decorate with any other ingredients",
  ],

  cook_time: 40,

  wait_time: 60,
};

export default Recipe;
