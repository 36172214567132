import Equipment from "./../equipment";
import Ingredients from "./../ingredients";
import { recipeTypes } from "../recipeTypes";

const Recipe = {
  type: recipeTypes.entree,

  title: "Red lentil soup",

  subtitle: "blended",

  background: require("../../media/red_lentil_soup.jpg"),

  details: ["Warm and hearty!", `Try it with buttered <a href='#/Soda_bread'>Soda bread</a>.`],

  equipment: [
    Equipment.Vertical_blender("IMPORTANT"),
    Equipment.Pot("Medium-large"),
    Equipment.Lid(),
    Equipment.Spatula(),
    Equipment.Cutting_board(),
    Equipment.Knife(),
  ],

  optional_equipment: [],

  ingredients: [
    Ingredients.Olive_oil(30, "2 Tablespoons"),
    Ingredients.Onion("1 large white", "chopped loosely"),
    Ingredients.Carrots(1, "peeled, chopped loosely"),
    Ingredients.Garlic("2 large cloves", "minced"),
    Ingredients.Tomato_paste("1 Tablespoon"),
    Ingredients.Cumin("1 teaspoon"),
    Ingredients.Red_lentils("1 teaspoon"),
    Ingredients.Vegetable_broth("1 liter of", "from 2 concentrated cubes"),
    Ingredients.Salt(),
    Ingredients.Pepper(),
  ],

  optional_ingredients: [Ingredients.Chilli_powder(), Ingredients.Butter(30)],

  prep: ["Chop the onion", "Peel and chop the carrot", "Peel and mince the garlic", "Make the vegetable broth"],

  prep_time: 10,

  method: [
    "In the soup pot heat the olive oil on medium",
    "Sautee the onion for 5 minutes, stirring often",
    "Add the garlic and stir, cook for 30 seconds",
    "Stir in tomato paste, cumin, a pinch of salt and pepper ",
    "(optional) Stir in the chilli powder",
    "Cook for another 2 minutes",
    "Add broth, lentils and carrot",
    "Bring to simmer then cover partially with lid and turn heat down",
    "Boil for 30 minutes",
    "Remove from heat and blend carefully with the vertical blender",
    "(optional) Add butter, blend more",
    "Taste and add more salt if needed, blend",
  ],

  cook_time: 45,
};

export default Recipe;
